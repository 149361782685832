import React, { useEffect } from 'react';
import Divider from '../components/common/Divider';
import classes from './Project.module.css';
import Footer from '../components/Footer/Footer';
import { useNavigate } from 'react-router-dom';

const projectData = {
  1: {
    name: 'NexaHomes Realty',
    stack: 'react, express, mongodb',
    description:
      'NexaHomes is an advanced real estate platform combining a React front-end with a Node.js/Express/MongoDB backend for dynamic, real-time property listings. The user interface, hosted on Netlify, features responsive design and seamless navigation through React Router. Our backend, deployed on Heroku, manages data efficiently, but may require a brief loading time if it has been idle. Property visuals are enhanced with DALLE-generated images and Adobe Photoshop, offering a visually appealing experience. Initially launched with static data, NexaHomes now delivers live property updates, reflecting our commitment to providing the most current listings available.\nNote: If accessing NexaHomes after a period of inactivity, please allow a moment for the Heroku backend to wake up and load the data.',
    status: 'complete',
    year: '2024',
    btnTxt: 'visit site',
    btnUrl: 'https://nexahomes-realty.netlify.app/',
    btnDisabled: false,
  },
  2: {
    name: 'Remingtons Gastropub',
    description:
      "Built a modern, visually appealing website for a small restaurant using Webflow and Adobe Photoshop, enhancing the restaurant's online presence and showcasing its offerings.",
    stack: 'Webflow',
    status: 'complete',
    year: '2024',
    btnTxt: 'visit site',
    btnUrl: 'https://remingtonsmalta.com',
    btnDisabled: false,
  },
  3: {
    name: '?????????',
    description:
      'An innovative app developed with Swift and Node.js, integrated with MongoDB. This app is a subtle yet powerful companion for international travel, designed to enhance the global journey experience in unique ways.',
    stack: 'Swift, MongoDB',
    status: 'in progress',
    year: '2024',
    btnTxt: 'in progress',
    btnUrl: 'https://portfolio-nft-project.netlify.app/',
    btnDisabled: true,
  },
  4: {
    name: 'NFT Marketplace',
    description:
      'This project focuses on crafting a responsive, static landing page for an NFT marketplace using React and React Router. The design prioritizes adaptability across different devices, ensuring a seamless user experience. Key features include a curated display of digital art and collectibles, auction details, and artist profiles, all presented within a secure and easy-to-navigate interface. This landing page acts as an inviting gateway to a diverse digital art ecosystem, highlighting the intersection of technology and creativity.',
    stack: 'React',
    status: 'complete',
    year: '2022',
    btnTxt: 'view site',
    btnUrl: 'https://portfolio-nft-project.netlify.app/',
    btnDisabled: false,
  },
  5: {
    name: 'Rapid-Deployment Agency',
    description:
      "We've launched a web development agency aimed at empowering small businesses with swift and affordable online solutions. Specializing in Webflow, we guarantee the design, development, and deployment of fully functional websites in just 24 hours. Recognizing the distinct challenges faced by small enterprises, our services are priced to accommodate their budgets while meeting their unique requirements and aspirations. Our commitment is to provide personalized, high-quality web solutions that help small businesses establish and amplify their online presence effectively.",
    stack: 'Webflow',
    status: 'ongoing',
    year: '2024',
    btnTxt: 'view site',
    btnUrl: 'https://dekalb-webdev.webflow.io/',
    btnDisabled: false,
  },
  // 6: {
  //   name: 'Rapid-Deployment Real Estate Website',
  //   description:
  //     "Efficiently constructed and launched a website for a real estate company within an impressive three-day timeframe, using Webflow. The project involved not only the design and development of the site but also the meticulous uploading and integration of the client's extensive property data. This swift execution from start to finish provided the real estate company with a professional and comprehensive online presence, featuring property listings, company information, and contact details, all tailored to enhance user engagement and facilitate business growth. The project's rapid completion demonstrates a commitment to meeting client needs with speed and precision.",
  //   stack: 'Webflow',
  //   status: 'complete',
  //   year: '2023',
  //   btnTxt: 'view site',
  //   btnUrl: 'https://portfolio-nft-project.netlify.app/',
  //   btnDisabled: false,
  // },
  // 7: {
  //   name: 'Rapid-Deployment Restaurant Website',
  //   description:
  //     "Expedited the development and hosting of a restaurant website within a few hours of the client's request. Utilizing Webflow, the project focused on creating a visually appealing and user-friendly online presence for the restaurant, with features like menu showcases and location information. The rapid turnaround from initial request to live hosting exemplifies efficiency and responsiveness, providing the client with an immediate online platform to engage with customers.",
  //   stack: 'Webflow',
  //   status: 'complete',
  //   year: '2024',
  //   btnTxt: 'view site',
  //   btnUrl: 'https://portfolio-nft-project.netlify.app/',
  //   btnDisabled: false,
  // },
};
export const Project = ({ projectId }) => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  const navigate = useNavigate();

  const disabledBtn = projectData[projectId].btnDisabled
    ? classes.btn_disabled
    : '';
  return (
    <div className='App'>
      <section className={classes.project}>
        <div className={classes.navbar}>
          <Divider />
          {/* <Link to='/' className={classes.back_btn}>
            <ion-icon name='arrow-back-outline'></ion-icon>
            <span>back</span>
          </Link> */}
          <button className={classes.back_btn} onClick={() => navigate(-1)}>
            <ion-icon name='arrow-back-outline'></ion-icon>
            <span>back</span>
          </button>
          <div className={classes.navbar_date}>
            <p className={classes.navbar_date_top}>project</p>
            <p className={classes.navbar_date_bottom}>
              {projectData[projectId].year}
            </p>
          </div>
          <p className={classes.navbar_brand}>jakob.io</p>
        </div>
        <div className={classes.heading_container}>
          <h2 className={classes.heading}>
            <Divider />
            {projectData[projectId].name}
          </h2>
        </div>
        <div className={classes.grid_12_col}>
          <p className={classes.detail_label}>details</p>
          <p className={classes.description}>
            {projectData[projectId].description}
          </p>

          <div className={classes.link_container}>
            <a
              href={projectData[projectId].btnUrl}
              target='_blank'
              rel='noreferrer'
              className={`${classes.site_link} ${disabledBtn}`}
            >
              {projectData[projectId].btnTxt}
            </a>
          </div>

          <div className={classes.project_info_container}>
            <Divider />
            <div className={classes.project_info_block}>
              <h4 className={classes.info_title}>technologies used</h4>
              <p className={classes.info_description}>
                {projectData[projectId].stack}
              </p>
              <span className={classes.bottom_divider}></span>
            </div>
            <div className={classes.project_info_block}>
              <h4 className={classes.info_title}>status</h4>
              <p className={classes.info_description}>
                {projectData[projectId].status}
              </p>
              <span className={classes.bottom_divider}></span>
            </div>
            <div className={classes.project_info_block}>
              <h4 className={classes.info_title}>
                {projectData[projectId].year}
              </h4>
              <p className={classes.info_description}>year</p>
              <span className={classes.bottom_divider}></span>
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};
