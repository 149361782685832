import React from 'react';
import classes from './ProjectWrapper.module.css';
import GreyText from './GreyText';
import { Link } from 'react-router-dom';

const ProjectWrapper = (props) => {
  return (
    <Link to={props.to} className={classes.project_wrapper}>
      <p className={classes.project_date}>{props.date}</p>
      <div className={classes.project_details}>
        <p className={classes.project_name}>{props.name}</p>
        <GreyText text={props.description} />
      </div>
      <div className={classes.divider}></div>
      <div className={classes.load_bar}></div>
      <div className={classes.arrow}>
        <ion-icon name='arrow-forward-circle-outline'></ion-icon>
      </div>
    </Link>
  );
};

export default ProjectWrapper;
