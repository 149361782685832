import React, { Fragment } from 'react';
// import { Fragment } from 'react';
import Divider from '../common/Divider';

import classes from './Navbar.module.css';
const Navbar = () => {
  return (
    <Fragment>
      <header className={classes.header}>
        <Divider />
        <div className={classes.brand}>
          <a href='https://jndev.io' className={classes.brand_link}>
            jndev.io
          </a>
        </div>
        <nav className={classes.nav}>
          <ul className={classes.nav_ul}>
            <li className={classes.nav_li}>
              <a href='#projects' className='nav-link'>
                projects
              </a>
            </li>
            <li className={classes.nav_li}>
              <a href='#about' className='nav-link'>
                about
              </a>
            </li>
            <li className={classes.nav_li}>
              <a href='#contact' className='nav-link'>
                contact
              </a>
            </li>
          </ul>
        </nav>
      </header>
    </Fragment>
  );
};

export default Navbar;
