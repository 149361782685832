import React from 'react';
import classes from './Label.module.css';
const Label = (props) => {
  const labelStyle = {
    gridColumn: props.gridCol || 'auto',
    gridRow: props.gridRow || 'auto',
    alignSelf: props.alignSelf || 'auto',
    width: props.width || 'auto',
  };

  return (
    <p className={classes.label} style={labelStyle}>
      {props.text}
    </p>
  );
};

export default Label;
