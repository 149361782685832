import {
  createBrowserRouter,
  RouterProvider,
  useLocation,
} from 'react-router-dom';
import Home from './pages/Home';
import { Project } from './pages/Project';
import { useEffect } from 'react';
// import { ScrollToTop } from './helper-functions/ScrollToTop';
const router = createBrowserRouter([
  { path: '/', element: <Home /> },
  { path: '/project1', element: <Project projectId='1' /> },
  { path: '/project2', element: <Project projectId='2' /> },
  { path: '/project3', element: <Project projectId='3' /> },
  { path: '/project4', element: <Project projectId='4' /> },
  { path: '/project5', element: <Project projectId='5' /> },
  { path: '/project6', element: <Project projectId='6' /> },
]);

function App() {
  return <RouterProvider router={router} />;
}

export default App;
